<template>
  <div class="allmovie">
    <div class="" style="height: 5.625rem"></div>
    <!-- 分类 -->
    <div class="all_class px-88 mt-10 mb-20">
      <div class="mb-10 flex align-center" v-for="(item, index) in category" :key="index">
        <div class="title">
          {{ item.name }}
        </div>
        <div class="flex align-center">
          <div
            class="ml-20 font-14 text-222222 cursorp"
            :class="item.category_ids == items.id ? 'font-weight-bolder' : ''"
            v-for="(items, indexs) in item.child"
            :key="indexs"
            @click="change(items.id, index)"
          >
            {{ items.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="font-22 py-10 px-88">{{ title }}</div>
    <!-- 内容 -->
    <div class="flex flex-wrap px-88">
      <div
        v-for="(item, index) in film"
        :key="index"
        class="mr-15 mb-20 cursorp"
        @click="gofilmdetails(item.id)"
      >
        <!-- <img
          :src="item.img_shu"
          width="12.3125rem"
          height="16.9375rem"
          class="rounded3"
          alt=""
        /> -->
        <el-image
          class="rounded3"
          style="width: 12.3125rem; height: 16.9375rem"
          :src="item.img_shu"
          fit="cover"
        ></el-image>
        <div class="text-ellipsis font-14 w197 mt-5 font-weight-bolder">
          {{ item.name }}
        </div>
        <div class="text-ellipsis font-12 w197 text-999999 mt-10">
          {{ item.abstract }}
        </div>
      </div>
    </div>
    <div class="flex align-center justify-center py-50 border-top border-ligit-BFBFBF">
      <el-pagination
        background
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :page-count="page_nums"
        @current-change="currentChange"
        :current-page="page"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import req from "../../utils/req";
export default {
  data() {
    return {
      category_ids: "",
      film: [],
      page: 1,
      page_nums: 0,
      category: "",
      classid: "",
      title: "",
      navid: "",
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let classid = this.$route.query.classid;
        let title = this.$route.query.title;
        let navid = this.$route.query.navid;
        if (classid && navid) {
          this.classid = classid;
          this.navid = navid;
          this.getdata();
        }
        if (title) {
          this.title = title;
        }
        // if (navid) {
        //   this.navid = navid;
        // }
      },
      deep: true,
    },
  },
  mounted() {
    let navid = this.$route.query.navid;
    let classid = this.$route.query.classid;
    let title = this.$route.query.title;
    if (classid && navid) {
      this.classid = classid;
      this.navid = navid;
      this.getdata();
    }
    if (title) {
      this.title = title;
    }
    // if (navid) {
    //   this.navid = navid;
    // }
  },
  methods: {
    // 跳转详情
    gofilmdetails(id) {
      this.$router.push({
        path: "/filmdetails",
        query: { type: "filmdetails", moveid: id },
      });
    },
    change(id, index) {
      this.category[index].category_ids = id;
      let arr = [];
      if (this.category && this.category.length) {
        this.category.forEach((item) => {
          if (item.category_ids) {
            arr.push(item.category_ids);
          }
        });
      }
      this.category_ids = arr.join(",");
      console.log(this.category_ids);
      console.log(this.category);
      this.getdata2();
    },
    getdata2() {
      let that = this;
      req
        .post("index/film", {
          nav_id: that.navid,
          sort: that.classid,
          category_ids: that.category_ids,
          page: that.page,
          nums: 14,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.film = res.data.film.data;
          that.page_nums = res.data.film.last_page;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("index/film", {
          nav_id: that.navid,
          sort: that.classid,
          category_ids: that.category_ids,
          page: that.page,
          nums: 14,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          if (res.data.category && res.data.category.length) {
            let obj = {
              id: "",
              name: "全部",
            };

            res.data.category.forEach((item) => {
              if (!that.category_ids) {
                item.category_ids = "";
              }
              if (item.child && item.child.length) {
                item.child.unshift(obj);
              }
            });
          }
          that.category = res.data.category;
          that.film = res.data.film.data;
          that.page_nums = res.data.film.last_page;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata();
    },
  },
};
</script>

<style lang="scss">
.allmovie {
  .all_class {
    .title {
      font-size: 0.875rem;
      color: #ff524c;
      padding: 0.6875rem 1.0625rem;
      border-radius: 0.5625rem;
      background: #feefeb;
    }
  }
  .w197 {
    width: 12.3125rem;
  }
}
</style>
